.ContactUsContainer {
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background: url('../images/IMG-20230920-WA0030.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    color: white;
    /* font-size: 1.2em; */
    overflow: auto;
    font-size: 1.2rem;
    font-family: 'Times New Roman', Times, serif;


}

.ContactUsInner {

    height: 40vh;
}

.ContactUsInner::after {
    color: white;
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0px;
    right: 0px;
    content: "";
    background-color: rgba(0, 0, 0, 0.577);
    z-index: -1;
}

.TelEmailDiv{
    background-color: rgba(0, 0, 0, 0.663);
}
.Email,.Telephone {
    display: flex;
    align-items: baseline;
    /* justify-content: space-around; */
}



.TelephoneEntity {
    font-size: 1.7rem;
    color: yellow;
    text-decoration: underline;
}

.EmailEntity {
    font-size: 1.7rem;
    color: rgb(77, 255, 0);
    text-decoration: underline;
}