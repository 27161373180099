.ProductContainer {

    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background: url('../images/logo.jpg');
    background-position: center;
    background-size: contain;

}