.PastryContainer {
    padding: 10px;
    display: flex;
    justify-content: center;
    justify-self: center;
    flex-wrap: wrap;
    height: 60vh;
    width: 100%;
    overflow: auto;

}

.PastryImageLogo {
    display: flex;
    justify-content: center;

}

.PastryImageLogo img {
    width: 400px;
    height: 300px;


}

.PasteryDetails {
    height: 50vh;
    padding: 10px;
    color: rgb(0, 0, 0);
    font-size: 1.1rem;
    font-family: 'Times New Roman', Times, serif;
}

/* 
@media(min-width:1920px) {

    .PastryImageLogo {
        display: none;
    }


} */

@media(max-width:500px) {

    .PastryImageLogo img {
        width: 350px;
        height: 280px;
    
    
    }
    


}