


.SliderContain {
    overflow: auto;
    height: 47vh;
    width: 100%;

}


.LocTimeContain {

    overflow: auto;
    height: 50vh;

}

.Locaton_Time {

    font-size: 1.2rem;
    font-family: 'Times New Roman', Times, serif;
    display: flex;
    justify-content: space-between;
    align-items:baseline;
    justify-content: center;
    height: 40vh;
    padding: 1em;
    box-sizing: border-box;
    
}

.Location {

    padding: 2px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;


}


.LocationTitle {
    align-self: flex-start;
    font-family: 'Times New Roman', Times, serif;

    color: crimson;
    font-size: 1.1rem;
}

.HoursTitle {
    align-self: flex-start;
    font-family: 'Times New Roman', Times, serif;
    color: crimson;
    font-size: 1.2rem;
}
.Time { 
    background-color: rgba(128, 0, 128, 0.061);
}
